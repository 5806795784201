// src/pages/resourcesPage.js

import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Typography,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import { fetchAuditsByYear, updateAuditDatesThunk } from "../store/auditSlice";
import GanttChart from "../components/GanttChart";
import StatusLegend from "../components/StatusLegend";

// Type color mapping
const typeColors = {
  Операционный: "#FFC107", // Operational - amber
  ИТ: "#2196F3", // IT - blue
  ИБ: "#4CAF50", // Information Security - green
  default: "#9C27B0", // Default for unknown types - purple
};

const AuditPlanPage = () => {
  const dispatch = useDispatch();
  const allAudits = useSelector((state) => state.audits.audits || []);
  const loading = useSelector((state) => state.audits.status === "loading");
  const selectedYear =
    useSelector((state) => state.audits.selectedYear) ||
    new Date().getFullYear();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });
  const [sortBy, setSortBy] = useState("name"); // Default sort by name

  // Filter to only include audits with status 2 (Approved/Утвержден)
  const unsortedAudits = allAudits.filter((audit) => audit.status === 2);

  // Sort audits based on selected sort criteria
  const audits = useMemo(() => {
    return [...unsortedAudits].sort((a, b) => {
      switch (sortBy) {
        case "name":
          return a.name.localeCompare(b.name);
        case "startDate":
          return new Date(a.start_date) - new Date(b.start_date);
        case "type":
          // Handle null or undefined types by sorting them last
          if (!a.type) return 1;
          if (!b.type) return -1;
          return a.type.localeCompare(b.type);
        default:
          return 0;
      }
    });
  }, [unsortedAudits, sortBy]);

  const handleSortChange = (event) => {
    setSortBy(event.target.value);
  };

  // Function to get color based on audit type
  const getAuditColor = (audit) => {
    return typeColors[audit.type] || typeColors.default;
  };

  useEffect(() => {
    dispatch(fetchAuditsByYear(selectedYear));
  }, [dispatch, selectedYear]);

  const handleDateChange = (auditId, dateData) => {
    dispatch(updateAuditDatesThunk({ auditId, dateData }))
      .unwrap()
      .then(() => {
        setSnackbar({
          open: true,
          message: "Даты аудита успешно обновлены",
          severity: "success",
        });
      })
      .catch((error) => {
        console.error("Failed to update audit dates:", error);
        setSnackbar({
          open: true,
          message: "Ошибка при обновлении дат аудита",
          severity: "error",
        });
      });
  };

  const handleSnackbarClose = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  // Generate timeline for the whole selected year
  const generateYearTimeline = () => {
    const year = selectedYear;
    const dates = [];

    // Create date for the first day of the year
    const startDate = new Date(year, 0, 1); // January 1st

    // Add all days of the year
    for (let i = 0; i < 366; i++) {
      // Max 366 days (leap year)
      const currentDate = new Date(startDate);
      currentDate.setDate(startDate.getDate() + i);

      // Break if we've gone to the next year
      if (currentDate.getFullYear() > year) break;

      dates.push(currentDate);
    }

    return dates;
  };

  // Set padding to 5mm on x-axis
  return (
    <Container maxWidth="xl" sx={{ px: "5mm", overflowX: "hidden" }}>
      {" "}
      {/* Prevent horizontal overflow */}
      <Typography variant="h4" component="h1" gutterBottom sx={{ mt: 3 }}>
        План аудитов
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="body2" color="text.secondary">
          График отображает только аудиты со статусом "Утвержден".
          {allAudits.length > 0 &&
            audits.length === 0 &&
            " Сейчас нет утвержденных аудитов для отображения."}
        </Typography>

        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel id="sort-by-label">Сортировать по</InputLabel>
          <Select
            labelId="sort-by-label"
            id="sort-by-select"
            value={sortBy}
            label="Сортировать по"
            onChange={handleSortChange}
            size="small"
          >
            <MenuItem value="name">Названию аудита</MenuItem>
            <MenuItem value="startDate">Дате начала</MenuItem>
            <MenuItem value="type">Типу аудита</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <GanttChart
        items={audits}
        title="График аудитов"
        getItemColor={getAuditColor}
        onDateChange={handleDateChange}
        loading={loading}
        labelHeader="Куратор"
        emptyMessage="Нет согласованных аудитов для отображения. Только аудиты со статусом 'Утвержден' отображаются на графике."
        calculateTimelineDates={generateYearTimeline}
        sx={{ overflowX: "hidden" }} // Prevent horizontal scrolling
      />
      <StatusLegend
        statusColors={typeColors}
        statusLabels={typeColors}
        sx={{ mt: 3 }}
        title="Обозначения по типам"
      />
      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AuditPlanPage;
