import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAuditsByYear, updateAuditStatus, fetchAuditStatuses } from '../store/auditSlice';
import {
  Container,
  Typography,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Chip,
  Stack,
  Snackbar,
  Alert,
  CircularProgress} from '@mui/material';
import { alpha } from '@mui/material/styles';

const CoordinationPage = () => {
  const dispatch = useDispatch();
  const audits = useSelector(state => state.audits.audits || []);
  const loading = useSelector(state => state.audits.status === 'loading');
  const selectedYear = useSelector(state => state.audits.selectedYear) || new Date().getFullYear();
  
  // Group audits by status (moved out of JSX to comply with hooks rules)
  const auditsByStatusGroups = useMemo(() => {
    // Define statuses to show, in order of importance
    const statusOrder = ['needs_revision', 'no_info', 'approved', 'rejected'];
    const auditsByStatus = {};
    
    // Initialize status groups
    statusOrder.forEach(status => {
      auditsByStatus[status] = [];
    });
    
    // Group audits by their status
    audits.forEach(audit => {
      const status = audit.status_text || 'no_info';
      if (auditsByStatus[status]) {
        auditsByStatus[status].push(audit);
      } else {
        auditsByStatus['no_info'].push(audit);
      }
    });
    
    return { statusOrder, auditsByStatus };
  }, [audits]);
  
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  useEffect(() => {
    dispatch(fetchAuditsByYear(selectedYear));
    dispatch(fetchAuditStatuses());
  }, [dispatch, selectedYear]);

  const handleStatusChange = (auditId, newStatus) => {
    dispatch(updateAuditStatus({ auditId, status: newStatus }))
      .unwrap()
      .then(() => {
        let message = 'Статус аудита успешно обновлен';
        if (newStatus === 'approved') {
          message = 'Аудит успешно согласован';
        } else if (newStatus === 'rejected') {
          message = 'Аудит отклонен';
        } else if (newStatus === 'needs_revision') {
          message = 'Аудит отправлен на доработку';
        }
        
        setSnackbar({
          open: true,
          message: message,
          severity: 'success'
        });
      })
      .catch(error => {
        console.error('Failed to update status:', error);
        setSnackbar({
          open: true,
          message: 'Ошибка при обновлении статуса',
          severity: 'error'
        });
      });
  };

  const handleSnackbarClose = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const getStatusChip = (status) => {
    let color, label;
    
    switch (status) {
      case 'approved':
        color = 'success';
        label = 'Согласован';
        break;
      case 'rejected':
        color = 'error';
        label = 'Отклонен';
        break;
      case 'needs_revision':
        color = 'warning';
        label = 'Требует доработки';
        break;
      case 'no_info':
      default:
        color = 'default';
        label = 'Не выбран';
        break;
    }
    
    return <Chip label={label} color={color} size="small" />
  };
  
  const getStatusTitle = (status) => {
    switch (status) {
      case 'approved':
        return 'Согласованные аудиты';
      case 'rejected':
        return 'Отклоненные аудиты';
      case 'needs_revision':
        return 'Аудиты требующие доработки';
      case 'no_info':
      default:
        return 'Аудиты без статуса';
    }
  };

  const getStatusBackgroundColor = (status) => {
    switch (status) {
      case 'approved':
        return alpha('#4caf50', 0.1);
      case 'rejected':
        return alpha('#f44336', 0.1);
      case 'needs_revision':
        return alpha('#ff9800', 0.1);
      case 'no_info':
      default:
        return alpha('#ffeb3b', 0.05);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Согласование
      </Typography>
      
      {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 3, mb: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            {audits.length > 0 ? (
              <>
                {auditsByStatusGroups.statusOrder.map((status, index) => {
                  const statusAudits = auditsByStatusGroups.auditsByStatus[status];
                  
                  // Skip rendering if no audits in this status
                  if (!statusAudits || statusAudits.length === 0) return null;
                  
                  return (
                    <Paper 
                      key={status} 
                      elevation={3} 
                      sx={{ 
                        width: '100%', 
                        overflow: 'hidden',
                        mb: 4,
                        backgroundColor: alpha(status === 'no_info' ? '#f5f5f5' : getStatusBackgroundColor(status), 0.3)
                      }}
                    >
                      <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
                        <Typography variant="h6">{getStatusTitle(status)}</Typography>
                      </Box>
                      
                      <TableContainer sx={{ tableLayout: 'fixed' }}>
                        <Table sx={{ tableLayout: 'fixed' }}>
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ width: '25%' }}>Название аудита</TableCell>
                              <TableCell sx={{ width: '15%' }}>Тип аудита</TableCell>
                              <TableCell sx={{ width: '12%' }}>Дата начала</TableCell>
                              <TableCell sx={{ width: '12%' }}>Дата окончания</TableCell>
                              <TableCell sx={{ width: '15%' }}>Статус</TableCell>
                              <TableCell sx={{ width: '21%' }} align="right">Действия</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {statusAudits.map((audit) => (
                              <TableRow 
                                key={audit.id} 
                                sx={{ '&:hover': { backgroundColor: alpha('#000', 0.04) } }}
                              >
                                <TableCell sx={{ width: '25%' }}><Typography variant="body2" fontWeight="medium">{audit.name}</Typography></TableCell>
                                <TableCell sx={{ width: '15%' }}>{audit.type || 'Не указан'}</TableCell>
                                <TableCell sx={{ width: '12%' }}>{new Date(audit.start_date).toLocaleDateString()}</TableCell>
                                <TableCell sx={{ width: '12%' }}>{new Date(audit.end_date).toLocaleDateString()}</TableCell>
                                <TableCell sx={{ width: '15%' }}>{getStatusChip(audit.status_text)}</TableCell>
                                <TableCell sx={{ width: '21%' }} align="right">
                                  <Stack direction="row" spacing={1} justifyContent="flex-end">
                                    <Button 
                                      variant="contained" 
                                      size="small" 
                                      color="success" 
                                      onClick={() => handleStatusChange(audit.id, 'approved')}
                                      disabled={audit.status_text === 'approved'}
                                    >
                                      Согласовать
                                    </Button>
                                    <Button 
                                      variant="contained" 
                                      size="small" 
                                      color="error" 
                                      onClick={() => handleStatusChange(audit.id, 'rejected')}
                                      disabled={audit.status_text === 'rejected'}
                                    >
                                      Отклонить
                                    </Button>
                                    <Button 
                                      variant="contained" 
                                      size="small" 
                                      color="warning" 
                                      onClick={() => handleStatusChange(audit.id, 'needs_revision')}
                                      disabled={audit.status_text === 'needs_revision'}
                                    >
                                      Доработать
                                    </Button>
                                  </Stack>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  );
                })}
              </>
            ) : (
              <Paper elevation={3} sx={{ width: '100%', overflow: 'hidden' }}>
                <Box sx={{ p: 3, textAlign: 'center' }}>
                  <Typography variant="body1" color="text.secondary">
                    Нет доступных аудитов для согласования
                  </Typography>
                </Box>
              </Paper>
            )}
          </Box>
        )}
      
      {/* No longer need Delete Confirmation Dialog */}
      
      {/* Snackbar for notifications */}
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={5000} 
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} variant="filled">
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default CoordinationPage;
