// api/user.js

import {
  BASE_URL_PLANNING,
  fetchWithAuth,
  getHeaders,
  handleResponse,
} from "./utils";

export const getCurrentUserInfo = async () => {
  const url = `${BASE_URL_PLANNING}/api/users/me/`;
  return fetchWithAuth(url, {
    method: "GET",
    headers: getHeaders(true),
  });
};

export const getUserInfo = async (userId) => {
  const url = `${BASE_URL_PLANNING}/api/users/${userId}/`;
  return fetchWithAuth(url, {
    method: "GET",
    headers: getHeaders(true),
  });
};

export const registerUser = async (userData) => {
  const url = `${BASE_URL_PLANNING}/api/register/`;

  const response = await fetch(url, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(userData),
  });

  return handleResponse(response);
};

export const getUsers = async () => {
  const url = `${BASE_URL_PLANNING}/api/all-users/`;
  return fetchWithAuth(url, {
    method: "GET",
    headers: getHeaders(true),
  });
};
