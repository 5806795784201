import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Menu, MenuItem, Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MainCharts from '../components/mainCharts';
import { mockMainChartsLineData, mockMainChartsBarData } from '../components/mainCharts/mockMainCharts';
import { createAuditPaperThunk } from '../store/auditSlice';

const DocumentsPage = () => {
    const dispatch = useDispatch();
    const selectedAudit = useSelector((state) => state.audits.selectedAudit);
    const auditPapers = useSelector((state) => state.audits.auditPapers);

    const [anchorEl, setAnchorEl] = useState(null);
    const [displayedPapers, setDisplayedPapers] = useState([]);

    useEffect(() => {
        if (selectedAudit) {
            // Фильтруем документы по текущему выбранному аудиту
            const filteredPapers = auditPapers.filter((paper) => paper.audit === selectedAudit.id);
            setDisplayedPapers(filteredPapers);
        } else {
            setDisplayedPapers([]); // Очищаем список при отсутствии выбранного аудита
        }
    }, [selectedAudit, auditPapers]);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleCreatePaper = (type) => {
        if (selectedAudit) {
            dispatch(createAuditPaperThunk({
                auditId: selectedAudit.id,
                paperData: { type }
            }));
        }
        handleMenuClose();
    };

    return (
        <>
            <MainCharts 
                title="Графики" 
                lineChartData={mockMainChartsLineData} 
                barChartData={mockMainChartsBarData}
                isShowDownloadBtn
            />

            <Box sx={{ marginTop: 4, display: 'flex', flexDirection: 'column', gap: 2 }}>
                {displayedPapers.map((paper) => (
                    <Paper key={paper.id} sx={{ padding: 2, backgroundColor: '#f5f5f5' }}>
                        {paper.type === 'table' ? (
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {Array.from({ length: paper.content.columns }).map((_, colIndex) => (
                                                <TableCell key={colIndex}>Колонка {colIndex + 1}</TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {paper.content.data.map((row, rowIndex) => (
                                            <TableRow key={rowIndex}>
                                                {row.map((cell, colIndex) => (
                                                    <TableCell key={colIndex}>{cell || ''}</TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <Typography variant="body1">{paper.content.text || 'Пустая строка'}</Typography>
                        )}
                    </Paper>
                ))}
            </Box>

            <Box sx={{ position: 'fixed', bottom: 20, right: 20 }}>
                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={handleMenuOpen} 
                    sx={{ borderRadius: '50%', minWidth: '56px', height: '56px' }}
                >
                    <AddIcon />
                </Button>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={() => handleCreatePaper("string")}>Строка</MenuItem>
                    <MenuItem onClick={() => handleCreatePaper("table")}>Таблица</MenuItem>
                </Menu>
            </Box>
        </>
    );
};

export default DocumentsPage;
