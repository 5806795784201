// src/api/audit.js

import { BASE_URL_PLANNING, fetchWithAuth, getHeaders } from './utils';

export const createAuditPaper = async (auditId, paperData) => {
    const url = `${BASE_URL_PLANNING}/api/audit-papers/${auditId}/create/`;
    return fetchWithAuth(url, {
        method: 'POST',
        headers: getHeaders(true),
        body: JSON.stringify(paperData),
    });
};

export const deleteAuditPaper = async (paperId) => {
    const url = `${BASE_URL_PLANNING}/api/audit-papers/${paperId}/delete/`;
    return fetchWithAuth(url, {
        method: 'DELETE',
        headers: getHeaders(true),
    });
};

export const getAuditPapers = async (auditId) => {
    const url = `${BASE_URL_PLANNING}/api/audit-papers/${auditId}/get-papers/`;
    return fetchWithAuth(url, {
        method: 'GET',
        headers: getHeaders(true),
    });
};

export const updateAuditPaper = async (paperId, paperData) => {
    const url = `${BASE_URL_PLANNING}/api/audit-papers/${paperId}/update/`;
    return fetchWithAuth(url, {
        method: 'PATCH',
        headers: getHeaders(true),
        body: JSON.stringify(paperData),
    });
};

export const generateAndSaveAuditProgram = async (auditId) => {
    const url = `${BASE_URL_PLANNING}/api/audit-programs/${auditId}/generate_and_save/`;
    return fetchWithAuth(url, {
        method: 'POST',
        headers: getHeaders(true),
        // No need to include audit_id in the body as it's already in the URL path
    });
};

export const getAuditProgram = async (auditId) => {
    const url = `${BASE_URL_PLANNING}/api/audit-programs/${auditId}/get-program/`;
    return fetchWithAuth(url, {
        method: 'GET',
        headers: getHeaders(true),
    });
};

export const addAudit = async (auditData) => {
    const url = `${BASE_URL_PLANNING}/api/audits/create/`;
    return fetchWithAuth(url, {
        method: 'POST',
        headers: getHeaders(true),
        body: JSON.stringify(auditData)
    });
};

export const deleteAudit = async (auditId) => {
    const url = `${BASE_URL_PLANNING}/api/audits/${auditId}/delete/`;
    return fetchWithAuth(url, { 
        method: 'DELETE',
        headers: getHeaders(true)
    });
};

export const getAllAudits = async () => {
    const url = `${BASE_URL_PLANNING}/api/all-audits/`;
    return fetchWithAuth(url, { 
        method: 'GET',
        headers: getHeaders(true)
    });
};

export const getAuditsByYear = async (year) => {
    const url = `${BASE_URL_PLANNING}/api/audits/year/${year}/`;
    return fetchWithAuth(url, { 
        method: 'GET',
        headers: getHeaders(true)
    });
};

export const getAuditYears = async () => {
    const url = `${BASE_URL_PLANNING}/api/audits/years/`;
    return fetchWithAuth(url, { 
        method: 'GET',
        headers: getHeaders(true)
    });
};

export const addAuditorToAuditAPI = async (auditId, auditorId) => {
    const url = `${BASE_URL_PLANNING}/api/audits/add-auditor/`;
    const data = { audit_id: auditId, auditor_id: auditorId };

    return fetchWithAuth(url, {
        method: 'POST',
        headers: getHeaders(true),
        body: JSON.stringify(data)
    });
};

export const removeAuditorFromAuditAPI = async (auditId, auditorId) => {
    const url = `${BASE_URL_PLANNING}/api/audits/remove-auditor/`;
    const data = { audit_id: auditId, auditor_id: auditorId };

    console.log('DELETE Request URL:', url);
    console.log('DELETE Request Data:', data);

    return fetchWithAuth(url, {
        method: 'DELETE',
        headers: getHeaders(true),
        body: JSON.stringify(data)
    });
};

export const addCuratorToAuditAPI = async (auditId, curatorId) => {
    const url = `${BASE_URL_PLANNING}/api/audits/add-curator/`;
    const data = { audit_id: auditId, curator_id: curatorId };
  
    return fetchWithAuth(url, {
      method: 'POST',
      headers: getHeaders(true),
      body: JSON.stringify(data)
    });
  };

export const removeCuratorFromAuditAPI = async (auditId) => {
    const url = `${BASE_URL_PLANNING}/api/audits/remove-curator/`;
    const data = { audit_id: auditId };
  
    return fetchWithAuth(url, {
      method: 'POST',
      headers: getHeaders(true),
      body: JSON.stringify(data)
    });
  };

export const getAuditStatusesAPI = async () => {
    const url = `${BASE_URL_PLANNING}/api/audit-statuses/`;
    return fetchWithAuth(url, { 
        method: 'GET',
        headers: getHeaders(true)
    });
};

export const updateAuditStatusAPI = async (auditId, status) => {
    const url = `${BASE_URL_PLANNING}/api/audits/${auditId}/update-status/`;
    const data = { status };

    return fetchWithAuth(url, {
        method: 'PATCH',
        headers: getHeaders(true),
        body: JSON.stringify(data)
    });
};

export const updateAllProgramRows = async (rowsData) => {
    const url = `${BASE_URL_PLANNING}/api/audit-program-rows/update_all_rows/`;
    return fetchWithAuth(url, {
        method: 'PATCH',
        headers: getHeaders(true),
        body: JSON.stringify(rowsData),
    });
};

export const updateProgramRow = async (rowId, rowData) => {
    const url = `${BASE_URL_PLANNING}/api/audit-program-rows/${rowId}/update_program_row/`;
    return fetchWithAuth(url, {
        method: 'PATCH',
        headers: getHeaders(true),
        body: JSON.stringify(rowData),
    });
};

export const deleteProgramRow = async (rowId) => {
    const url = `${BASE_URL_PLANNING}/api/audit-program-rows/${rowId}/delete_program_row/`;
    return fetchWithAuth(url, {
        method: 'DELETE',
        headers: getHeaders(true),
    });
};

export const updateAuditDates = async (auditId, dateData) => {
    const url = `${BASE_URL_PLANNING}/api/audits/${auditId}/update/`;
    return fetchWithAuth(url, {
        method: 'PATCH',
        headers: getHeaders(true),
        body: JSON.stringify(dateData)
    });
};

export const downloadAuditProgram = async (auditId) => {
    const url = `${BASE_URL_PLANNING}/api/audit-programs/${auditId}/download/`;
    
    try {
        // Use fetchWithAuth with isBinary set to true to get a blob
        const blob = await fetchWithAuth(url, {
            method: 'GET',
            headers: getHeaders(true),
        }, true);
        
        // Create a temporary URL for the blob
        const downloadUrl = window.URL.createObjectURL(blob);
        
        // Create a temporary link element
        const link = document.createElement('a');
        link.href = downloadUrl;
        
        // Set the download attribute with filename
        link.download = `audit-program-${auditId}.xlsx`;
        
        // Append the link to the document
        document.body.appendChild(link);
        
        // Trigger the click event to start download
        link.click();
        
        // Clean up - remove the link and revoke the URL
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
        
        return { success: true };
    } catch (error) {
        console.error('Download failed:', error);
        throw error;
    }
};
