import React from 'react';
import { Typography, Box, Grid, Paper } from '@mui/material';

/**
 * Component for displaying a legend for colors
 * 
 * @param {Object} props Component props
 * @param {Object} props.statusColors Object mapping status/type values to colors
 * @param {Object} props.statusLabels Object mapping status/type values to display labels
 * @param {string} props.title Title for the legend
 * @param {Object} props.sx Additional MUI sx styles to apply to container
 */
const StatusLegend = ({ statusColors, statusLabels, title = "Обозначения статусов", sx = {} }) => {
  return (
    <Paper sx={{ p: 2, ...sx }}>
      <Typography variant="h6" gutterBottom>{title}</Typography>
      <Grid container spacing={2}>
        {Object.entries(statusColors).map(([status, color]) => (
          <Grid item key={status}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box 
                sx={{ 
                  width: 16, 
                  height: 16, 
                  bgcolor: color, 
                  borderRadius: 1, 
                  mr: 1 
                }} 
              />
              <Typography variant="body2">
                {status === "default" ? "Другие типы" : status}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

export default StatusLegend;
