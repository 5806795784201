import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './menu.scss';

const Menu = () => {
    const currentYearAuditCount = useSelector((state) => state.audits.currentYearAudits.length);

    return (
        <nav className="menu">
            <div className="menu__section">
                <h3 className="menu__section-title">Планирование</h3>
                <ul className="menu__list">
                    <li>
                        <NavLink end to="/plan" className={({ isActive }) => "menu__link" + (isActive ? " _active" : "")}>
                            <span className="icon icon-view-boxes" aria-hidden="true"></span>
                            <span className="menu__link-name">Генерация плана</span>
                            <span className="menu__link-count _bg-green">{currentYearAuditCount}</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink end to="/resourses" className={({ isActive }) => "menu__link" + (isActive ? " _active" : "")}>
                            <span className="icon icon-calendar-select-day" aria-hidden="true"></span>
                            <span className="menu__link-name">План аудитов</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink end to="/resource-plan" className={({ isActive }) => "menu__link" + (isActive ? " _active" : "")}>
                            <span className="icon icon-calendar-select-day" aria-hidden="true"></span>
                            <span className="menu__link-name">Ресурсный план</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink end to="/coordination" className={({ isActive }) => "menu__link" + (isActive ? " _active" : "")}>
                            <span className="icon icon-email" aria-hidden="true"></span>
                            <span className="menu__link-name">Согласование</span>
                        </NavLink>
                    </li>
                </ul>
            </div>
            <div className="menu__section">
                <h3 className="menu__section-title">Проекты</h3>
                <ul className="menu__list">
                    <li>
                        <NavLink end to="/audit" className={({ isActive }) => "menu__link" + (isActive ? " _active" : "")}>
                            <span className="icon icon-suitcase" aria-hidden="true"></span>
                            <span className="menu__link-name">Аудит</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink end to="/documents" className={({ isActive }) => "menu__link" + (isActive ? " _active" : "")}>
                            <span className="icon icon-inbox" aria-hidden="true"></span>
                            <span className="menu__link-name">Бумаги</span>
                            <span className="menu__link-count _bg-pink">10</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink end to="/analytics" className={({ isActive }) => "menu__link" + (isActive ? " _active" : "")}>
                            <span className="icon icon-chart-pie" aria-hidden="true"></span>
                            <span className="menu__link-name">Аналитика</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink end to="/report" className={({ isActive }) => "menu__link" + (isActive ? " _active" : "")}>
                            <span className="icon icon-view-table" aria-hidden="true"></span>
                            <span className="menu__link-name">Отчёт</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink end to="/notifications" className={({ isActive }) => "menu__link" + (isActive ? " _active" : "")}>
                            <span className="icon icon-notification" aria-hidden="true"></span>
                            <span className="menu__link-name">Уведомления</span>
                            <span className="menu__link-count _bg-red">10</span>
                        </NavLink>
                    </li>
                </ul>
            </div>
            <div className="menu__section">
                <h3 className="menu__section-title">Поручения</h3>
                <ul className="menu__list">
                    <li>
                        <NavLink end to="/tasks-list" className={({ isActive }) => "menu__link" + (isActive ? " _active" : "")}>
                            <span className="icon icon-view-week" aria-hidden="true"></span>
                            <span className="menu__link-name">Список</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink end to="/tasks-new" className={({ isActive }) => "menu__link" + (isActive ? " _active" : "")}>
                            <span className="icon icon-file-add" aria-hidden="true"></span>
                            <span className="menu__link-name">Новое</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink end to="/tasks-completed" className={({ isActive }) => "menu__link" + (isActive ? " _active" : "")}>
                            <span className="icon icon-trophy" aria-hidden="true"></span>
                            <span className="menu__link-name">Завершённые</span>
                        </NavLink>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Menu;
