import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUsers, getCurrentUserInfo } from "../api/user";

export const fetchCurrentUser = createAsyncThunk(
  "users/fetchCurrentUser",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getCurrentUserInfo();
      return response;
    } catch (error) {
      // Проверяем, есть ли у ошибки ответ от сервера
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      // Возвращаем общее сообщение об ошибке
      return rejectWithValue(error.message);
    }
  }
);

export const fetchUsers = createAsyncThunk(
  "users/fetchUsers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getUsers();
      return response;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

const transformUserData = (users) => {
  return users.map((user) => ({
    id: user.id,
    img: "",
    nameAuditor: `${user.first_name || ""} ${user.last_name || ""}`.trim(),
    jobPosition: user.position || "Не указано",
    tel: user.phone || "Не указан",
    mail: user.email,
    auditorAvatarUrl: "",
  }));
};

const userSlice = createSlice({
  name: "users",
  initialState: {
    users: [],
    transformedUsers: [],
    currentUser: null,
    usersStatus: "idle", // Статус для fetchUsers
    currentUserStatus: "idle", // Статус для fetchCurrentUser
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.usersStatus = "loading";
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.usersStatus = "succeeded";
        state.users = action.payload;
        state.transformedUsers = transformUserData(action.payload);
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.usersStatus = "failed";
        state.error = action.payload || action.error.message;
      })
      .addCase(fetchCurrentUser.pending, (state) => {
        state.currentUserStatus = "loading";
      })
      .addCase(fetchCurrentUser.fulfilled, (state, action) => {
        state.currentUserStatus = "succeeded";
        state.currentUser = action.payload;
      })
      .addCase(fetchCurrentUser.rejected, (state, action) => {
        state.currentUserStatus = "failed";
        state.error = action.payload || action.error.message;
      });
  },
});

export default userSlice.reducer;
