import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Grid, Typography, CircularProgress } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { fetchAuditYears, fetchAuditsByYear, setSelectedYear } from '../store/auditSlice';
import GeneratedAudits from '../components/generatedAudits/generatedAudits';
import AuditTable from '../components/auditTable/auditTable';
import { readDraftAuditsByYear } from '../store/draftAuditSlice';

const AuditGenerationPage = () => {
    const dispatch = useDispatch();
    const audits = useSelector((state) => state.audits.audits);
    const auditStatus = useSelector((state) => state.audits.status);
    const error = useSelector((state) => state.audits.error);
    const selectedYear = useSelector((state) => state.audits.selectedYear);

    useEffect(() => {
        dispatch(fetchAuditYears());
    }, [dispatch]);

    useEffect(() => {
        if (selectedYear) {
            dispatch(fetchAuditsByYear(selectedYear));
            dispatch(readDraftAuditsByYear(selectedYear));
        }
    }, [selectedYear, dispatch]);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Container style={{ padding: '1rem' }}>
                {auditStatus === 'loading' && (
                    <Grid container justifyContent="center" style={{ marginTop: '2rem' }}>
                        <CircularProgress />
                    </Grid>
                )}

                {!selectedYear && auditStatus !== 'loading' && (
                    <Typography variant="h15" style={{ marginTop: '10px' }}>
                        Пожалуйста, выберите год в верхней панели для отображения аудитов.
                    </Typography>
                )}

                {selectedYear && auditStatus === 'succeeded' && audits.length === 0 && (
                    <Typography variant="h15" style={{ marginTop: '10px' }}>
                        В выбранном году аудиты отсутствуют.
                    </Typography>
                )}

                {selectedYear && auditStatus === 'succeeded' && audits.length > 0 && (
                    <>
                        <Typography variant="h4" gutterBottom style={{ marginTop: '2rem' }}>
                            Генерация плана
                        </Typography>
                        <AuditTable data={audits} />
                    </>
                )}

                <Typography variant="h4" gutterBottom style={{ marginTop: '2rem' }}>
                    Сгенерировать план аудитов в ИИ
                </Typography>
                <GeneratedAudits year={selectedYear} />

                {auditStatus === 'error' && (
                    <Grid item xs={12} style={{ marginTop: '1rem', color: 'red' }}>
                        <p>Error: {error}</p>
                    </Grid>
                )}
            </Container>
        </LocalizationProvider>
    );
};

export default AuditGenerationPage;
