import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Box, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { fetchAuditProgram, updateProgramRowThunk, updateAllProgramRowsThunk } from "../../store/auditSlice";

const AuditProgramTable = ({ auditId, refreshTrigger, editMode = false }) => {
  const dispatch = useDispatch();
  const programData = useSelector((state) => state.audits.programData);
  const programStatus = useSelector((state) => state.audits.programStatus);
  const [editedRows, setEditedRows] = useState({});

  useEffect(() => {
    if (auditId) {
      dispatch(fetchAuditProgram(auditId));
    }
  }, [auditId, dispatch]);

  useEffect(() => {
    if (refreshTrigger && auditId) {
      dispatch(fetchAuditProgram(auditId));
    }
  }, [refreshTrigger, auditId, dispatch]);

  const columns = [
    {
      field: "index",
      headerName: "№",
      width: 50,
      editable: editMode,
    },
    {
      field: "goal",
      headerName: "Цель",
      width: 300,
      editable: editMode,
    },
    {
      field: "risk",
      headerName: "Риск",
      width: 250,
      editable: editMode,
    },
    {
      field: "risk_factor",
      headerName: "Фактор риска",
      width: 300,
      editable: editMode,
    },
    {
      field: "procedure",
      headerName: "Процедура тестирования",
      width: 400,
      editable: editMode,
    },
  ];

  const rows = [];
  programData.forEach((item) => {
    let goalDisplayed = false;
    let indexDisplayed = false;
    if (Array.isArray(item.json_data?.risks)) {
      item.json_data.risks.forEach((risk) => {
        let riskDisplayed = false;
        if (Array.isArray(risk.risk_factors)) {
          risk.risk_factors.forEach((factor) => {
            let factorDisplayed = false;
            if (Array.isArray(factor.procedures)) {
              factor.procedures.forEach((procedure, procIndex) => {
                rows.push({
                  id: `${item.id}-${risk.risk}-${factor.risk_factor}-${procIndex}`,
                  rowId: item.id,
                  index: indexDisplayed ? "" : item.index,
                  goal: goalDisplayed ? "" : item.json_data.goal,
                  risk: riskDisplayed ? "" : risk.risk,
                  risk_factor: factorDisplayed ? "" : factor.risk_factor,
                  procedure: procedure,
                  // Store references to help with mapping back to the original structure
                  _riskIndex: risk.risk,
                  _factorIndex: factor.risk_factor,
                  _procIndex: procIndex
                });
                goalDisplayed = true;
                riskDisplayed = true;
                factorDisplayed = true;
                indexDisplayed = true;
              });
            }
          });
        }
      });
    }
  });

  const handleProcessRowUpdate = (newRow, oldRow) => {
    // Store the edited row
    setEditedRows(prev => ({
      ...prev,
      [newRow.id]: newRow
    }));

    // Deep copy of the program data to modify
    const updatedProgramData = JSON.parse(JSON.stringify(
      programData.find(item => item.id === newRow.rowId)
    ));

    if (newRow.goal !== oldRow.goal && newRow.goal !== "") {
      updatedProgramData.json_data.goal = newRow.goal;
    }

    // Find the risk array item using the _riskIndex
    if (updatedProgramData.json_data.risks && updatedProgramData.json_data.risks.length > 0) {
      const riskIndex = updatedProgramData.json_data.risks.findIndex(r => r.risk === newRow._riskIndex);
      
      if (riskIndex !== -1) {
        // Update risk if it changed and is not empty
        if (newRow.risk !== oldRow.risk && newRow.risk !== "") {
          updatedProgramData.json_data.risks[riskIndex].risk = newRow.risk;
        }
        
        // Find the risk factor item
        if (updatedProgramData.json_data.risks[riskIndex].risk_factors) {
          const factorIndex = updatedProgramData.json_data.risks[riskIndex].risk_factors.findIndex(
            f => f.risk_factor === newRow._factorIndex
          );
          
          if (factorIndex !== -1) {
            // Update risk factor if it changed and is not empty
            if (newRow.risk_factor !== oldRow.risk_factor && newRow.risk_factor !== "") {
              updatedProgramData.json_data.risks[riskIndex].risk_factors[factorIndex].risk_factor = newRow.risk_factor;
            }
            
            // Update procedure if it changed
            if (newRow.procedure !== oldRow.procedure) {
              updatedProgramData.json_data.risks[riskIndex].risk_factors[factorIndex].procedures[newRow._procIndex] = newRow.procedure;
            }
          }
        }
      }
    }

    // If the row index changed and is not empty
    if (newRow.index !== oldRow.index && newRow.index !== "") {
      updatedProgramData.index = parseInt(newRow.index);
    }

    // Dispatch update to backend
    dispatch(updateProgramRowThunk({
      rowId: newRow.rowId,
      rowData: { json_data: updatedProgramData.json_data }
    }));

    return newRow;
  };

  const handleProcessRowUpdateError = (error) => {
    console.error('Error during row update:', error);
  };

  // Function to save all edited rows
  const saveAllChanges = () => {
    if (Object.keys(editedRows).length > 0) {
      const rowsToUpdate = programData.map(item => {
        const affectedRows = Object.values(editedRows).filter(row => row.rowId === item.id);
        if (affectedRows.length > 0) {
          // Deep copy of the program data to modify
          const updatedData = JSON.parse(JSON.stringify(item));
          return updatedData;
        }
        return item;
      });
      
      dispatch(updateAllProgramRowsThunk(rowsToUpdate));
      setEditedRows({});
    }
  };

  return (
    <Box sx={{ marginBottom: "2rem" }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
        <Typography variant="h6">
          Программа аудита (Таблица)
        </Typography>
        {editMode && Object.keys(editedRows).length > 0 && (
          <Button 
            variant="contained" 
            color="success" 
            onClick={saveAllChanges}
          >
            Сохранить изменения
          </Button>
        )}
      </Box>
      <DataGrid
        editMode="row"
        rows={rows}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        autoHeight
        getRowHeight={() => "auto"}
        processRowUpdate={handleProcessRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        loading={programStatus === 'loading'}
        sx={{
          "& .MuiDataGrid-cell": {
            whiteSpace: "normal",
            wordWrap: "break-word",
            lineHeight: "1.5rem",
            padding: "10px",
          },
        }}
      />
    </Box>
  );
};

export default AuditProgramTable;
