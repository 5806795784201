import React, { useState, useEffect } from "react";
import "./header.scss";
import png from "../../resources/images/avatar@1x.png";
import Avatar from "../avatar";
import { Button } from "../common/button";
import { InputText } from "../common/form-control";
import { Menu, MenuItem, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/authSlice";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  fetchAuditYears,
  fetchAuditsByYear,
  setSelectedYear,
} from "../../store/auditSlice";

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedYear = useSelector((state) => state.audits.selectedYear);

  useEffect(() => {
    dispatch(fetchAuditYears());
  }, [dispatch]);

  useEffect(() => {
    if (selectedYear) {
      dispatch(fetchAuditsByYear(selectedYear));
    }
  }, [selectedYear, dispatch]);

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = () => {
    handleMenuClose();
    navigate("/profile");
  };

  const handleLogout = () => {
    // First remove tokens
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");

    // Dispatch logout action to update Redux state
    dispatch(logout());

    // Close the menu
    handleMenuClose();

    // Redirect to login page
    navigate("/login");

    console.log("Токены удалены, пользователь вышел из системы");
  };

  const handleYearChange = (newValue) => {
    if (newValue) {
      dispatch(setSelectedYear(newValue.getFullYear()));
    } else {
      dispatch(setSelectedYear(null));
    }
  };

  return (
    <header className="header">
      <div className="header__left-part">
        <Button
          type="button"
          classes="btn-icon-circle"
          title="Меню"
          leftIcon="icon-menu"
        />
        <div className="search-control">
          <InputText
            id="search"
            label="Поиск"
            placeholder="Поиск"
            isScreenReaderOnly
          />
          <Button
            type="button"
            classes="btn-icon"
            title="Поиск"
            leftIcon="icon-search"
          />
        </div>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            views={["year"]}
            value={selectedYear ? new Date(selectedYear, 0) : null}
            onChange={handleYearChange}
            slotProps={{
              textField: {
                size: "small",
                sx: {
                  width: "320px",
                  height: "36px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "& .MuiInputBase-input": {
                    height: "34px",
                    padding: "0px 12px",
                  },
                },
                placeholder: "Выберите год",
              },
            }}
          />
        </LocalizationProvider>
      </div>
      <div className="header__right-part">
        <Button
          type="button"
          classes="btn-icon"
          title="Избранное"
          leftIcon="icon-heart _color-blue"
        />
        <Button
          type="button"
          classes="btn-icon _with-indicator"
          title="Уведомления"
          leftIcon="icon-notification _color-blue"
        />
        <Button
          type="button"
          classes="btn-icon"
          title="Сообщения"
          leftIcon="icon-chat _color-blue"
          onClick={() => navigate("/gpt")}
        />
        <Button
          type="button"
          classes="btn-icon"
          title="Настройки"
          leftIcon="icon-settings _color-blue"
        />
        <div>
          <Avatar
            src={png}
            alt="Аватар Иванова Ивана"
            onClick={handleAvatarClick}
            style={{ cursor: "pointer" }}
          />
        </div>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          MenuListProps={{
            sx: { boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)" },
          }}
          PaperProps={{
            style: {
              maxHeight: "200px",
            },
          }}
          keepMounted
        >
          <MenuItem onClick={handleProfileClick}>Профиль</MenuItem>
          <MenuItem onClick={handleLogout} sx={{ color: "red" }}>
            Выйти
          </MenuItem>
        </Menu>
      </div>
    </header>
  );
};

export default Header;
