// src/pages/userProfilePage.js

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCurrentUser } from "../store/userSlice";

const UserProfilePage = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.users.currentUser);
  const status = useSelector((state) => state.users.status);
  const error = useSelector((state) => state.users.error);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchCurrentUser());
    }
  }, [status, dispatch]);

  if (status === "loading") {
    return <div>Загрузка...</div>;
  }

  if (status === "failed") {
    return <div>Ошибка: {error}</div>;
  }

  if (!currentUser) {
    return null;
  }

  return (
    <div>
      <h1>
        {currentUser.first_name} {currentUser.last_name}
      </h1>
      <p>Позиция: {currentUser.position}</p>
      <p>Телефон: {currentUser.phone}</p>
      <p>Email: {currentUser.email}</p>
      {/* Отобразите другие данные по необходимости */}
    </div>
  );
};

export default UserProfilePage;
