import { Button, MenuItem, Select, Box, CircularProgress, Grid } from '@mui/material';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { fetchAuditsByYear, generateAuditProgram, setSelectedAudit, downloadAuditProgramThunk } from '../store/auditSlice';
import TeamList from '../components/teamList';
import ProjectSchedule from '../components/projectSchedule';
import mockProjectScheduleData from '../components/projectSchedule/mockProjectSchedule';
import AuditProgramTable from '../components/auditProgramTable/auditProgramTable';
import DownloadIcon from '@mui/icons-material/Download';

const AuditPage = () => {
    const dispatch = useDispatch();
    const audits = useSelector((state) => state.audits.audits); // Данные из store > audits > audits
    const auditStatus = useSelector((state) => state.audits.status);
    const error = useSelector((state) => state.audits.error);
    const selectedAudit = useSelector((state) => state.audits.selectedAudit);
    const programData = useSelector((state) => state.audits.programData);
    const selectedYear = useSelector((state) => state.audits.selectedYear);
    console.log("programData: ", programData);

    const [teamListData, setTeamListData] = useState([]); // Данные для TeamList
    const [refreshTrigger, setRefreshTrigger] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);

    useEffect(() => {
        if (selectedYear) {
            dispatch(fetchAuditsByYear(selectedYear));
        }
    }, [selectedYear, dispatch]);

    console.log("teamListData", teamListData)

    useEffect(() => {
        if (selectedAudit) {
            const curator = selectedAudit.curator;

            const teamList = [
                {
                    avatarUrl:
                        'https://img.freepik.com/free-photo/close-up-on-adorable-kitten-indoors_23-2150782471.jpg?w=740&t=st=1715798283~exp=1715798883~hmac=b76b283e598b43180be68b455509c1e43086a55c23322e918ad754ca09e1ad25',
                    name: curator
                        ? `${curator.first_name} ${curator.last_name}`
                        : 'Нет куратора',
                    jobPosition: 'Куратор',
                },
            ];

            setTeamListData(teamList);
        }
    }, [selectedAudit]);

    const handleGenerateProgram = async () => {
        if (selectedAudit) {
            try {
                setIsGenerating(true);
                await dispatch(generateAuditProgram(selectedAudit.id));
                setRefreshTrigger((prev) => !prev); // Обновляем для перезагрузки данных
            } catch (error) {
                console.error('Failed to generate program:', error);
                // Можно добавить оповещение пользователя об ошибке
            } finally {
                setIsGenerating(false);
            }
        }
    };

    const handleAuditChange = (event) => {
        const auditId = event.target.value; // Получаем ID выбранного аудита
        dispatch(setSelectedAudit(auditId)); // Устанавливаем выбранный аудит в сторе
    };

    const toggleEditMode = () => {
        setEditMode(prevMode => !prevMode);
    };

    const handleDownloadProgram = async () => {
        if (selectedAudit) {
            try {
                setIsDownloading(true);
                await dispatch(downloadAuditProgramThunk(selectedAudit.id));
                // No need to manually trigger download as it's handled in the API function
            } catch (error) {
                console.error('Failed to download program:', error);
                // You could add a toast or alert here to notify the user of the error
            } finally {
                setIsDownloading(false);
            }
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            {auditStatus === 'loading' && (
                <Grid container justifyContent="center" sx={{ marginTop: '2rem' }}>
                    <CircularProgress />
                </Grid>
            )}

            {!selectedYear && auditStatus !== 'loading' && (
                <Typography variant="body1" sx={{ marginTop: '1rem' }}>
                    Пожалуйста, выберите год в верхней панели для отображения аудитов.
                </Typography>
            )}

            {selectedYear && auditStatus === 'succeeded' && audits.length === 0 && (
                <Typography variant="body1" sx={{ marginTop: '1rem' }}>
                    В выбранном году аудиты отсутствуют.
                </Typography>
            )}

            {selectedYear && auditStatus === 'succeeded' && audits.length > 0 && (
                <>
                    <Select
                        value={selectedAudit ? selectedAudit.id : ''}
                        onChange={handleAuditChange}
                        displayEmpty
                        sx={{ marginRight: '10px', marginTop: '1rem' }}
                    >
                        <MenuItem value="" disabled>
                            Выберите аудит
                        </MenuItem>
                        {audits.map((audit) => (
                            <MenuItem key={audit.id} value={audit.id}>
                                {audit.name}
                            </MenuItem>
                        ))}
                    </Select>
                </>
            )}

            {selectedAudit && (
                <Box sx={{ marginTop: '2rem' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '1rem' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleGenerateProgram}
                            sx={{ marginRight: '10px' }}
                            disabled={isGenerating}
                        >
                            {isGenerating ? 'Генерация...' : 'Сгенерировать программу'}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleDownloadProgram}
                            startIcon={<DownloadIcon />}
                            sx={{ marginRight: '10px' }}
                            disabled={isDownloading}
                        >
                            {isDownloading ? 'Скачивание...' : 'Скачать программу'}
                        </Button>
                        <Button
                            variant="contained"
                            color={editMode ? "success" : "primary"}
                            onClick={toggleEditMode}
                        >
                            {editMode ? "Опубликовать" : "Редактировать"}
                        </Button>
                    </Box>
                    <AuditProgramTable 
                        auditId={selectedAudit.id} 
                        refreshTrigger={refreshTrigger} 
                        editMode={editMode}
                    />
                </Box>
            )}

            <Box sx={{ marginTop: '2rem' }}>
                <TeamList title="Команда" list={teamListData} />
            </Box>
            <Box sx={{ marginTop: '2rem' }}>
                <ProjectSchedule title="График проекта" shedule={mockProjectScheduleData} />
            </Box>

            {auditStatus === 'failed' && (
                <Typography variant="body1" sx={{ marginTop: '1rem', color: 'red' }}>
                    Error: {error}
                </Typography>
            )}
        </LocalizationProvider>
    );
};

export default AuditPage;
