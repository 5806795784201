import React, { useState, useRef } from "react";
import { Typography, Box, Grid, Tooltip, Divider, CircularProgress, Paper, TextField, IconButton } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import ruLocale from "date-fns/locale/ru";
import { styled, alpha } from "@mui/material/styles";

// Styled components for Gantt chart
const GanttContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  marginTop: theme.spacing(3),
  overflowX: "hidden", // Prevent horizontal scrolling
  width: "100%",
  maxWidth: "100%",
}));

const TimelineHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  borderBottom: `1px solid ${theme.palette.divider}`,
  marginBottom: theme.spacing(1),
  paddingBottom: theme.spacing(1),
}));

const DayCell = styled(Box)(({ theme }) => ({
  width: 7, // Reduced width to fit full year
  flexShrink: 0,
  textAlign: "center",
  padding: theme.spacing(0.5),
  fontSize: 10,
  fontWeight: "bold",
}));

const TaskRow = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1.5, 0), // Increased padding for taller rows
  borderBottom: `1px solid ${theme.palette.divider}`,
  minHeight: 55, // Added minimum height
}));

const TaskLabel = styled(Box)(({ theme }) => ({
  minWidth: 200,
  maxWidth: 200,
  flexShrink: 0,
  padding: theme.spacing(0, 2),
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  borderRight: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.background.default,
}));

// Handles for resizing
const ResizeHandle = styled(Box)(({ position }) => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  width: 10,
  cursor: 'ew-resize',
  zIndex: 10,
  backgroundColor: 'rgba(255, 255, 255, 0.3)',
  ...(position === 'left' ? { left: 0 } : { right: 0 }),
}));

const TaskBar = styled(Box)(({ theme, color }) => ({
  position: "absolute",
  height: 45, // Increased height to accommodate two lines of text
  borderRadius: 4,
  backgroundColor: color || "#757575",
  boxShadow: theme.shadows[2],
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "white",
  fontSize: 11,
  fontWeight: "bold",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "normal", // Allow text to wrap to multiple lines
  textAlign: "center", // Center text horizontally
  lineHeight: "1.2", // Tighter line height for wrapped text
  padding: theme.spacing(0.5, 1),
  cursor: "move",
  minWidth: 10,
  zIndex: 2,
  transition: 'transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[4],
    zIndex: 3
  },
}));

const TimelineGrid = styled(Box)(({ theme }) => ({
  position: "relative",
  flex: 1,
  minHeight: 50,
}));

const DateColumn = styled(Box)(({ theme }) => ({
  width: '130px',
  padding: theme.spacing(0.5),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRight: '1px solid #e0e0e0',
  borderBottom: '1px solid #e0e0e0',
  backgroundColor: theme.palette.background.default,
  flexShrink: 0,
}));

/**
 * Reusable Gantt Chart component for visualizing and managing timelines
 * 
 * @param {Object} props Component props
 * @param {Array} props.items Array of items to display in the Gantt chart
 * @param {string} props.title Title of the Gantt chart
 * @param {Object} props.statusColors Mapping of status values to colors (legacy)
 * @param {Function} props.getItemColor Function to get color based on item properties
 * @param {Function} props.onDateChange Callback when item dates change
 * @param {boolean} props.loading Whether data is loading
 * @param {string} props.labelHeader Header text for the label column
 * @param {string} props.emptyMessage Message to display when no items are present
 * @param {boolean} props.readOnly If true, disables interactions (resize/move)
 * @param {Function} props.calculateTimelineDates Function to generate the timeline dates
 * @param {Object} props.sx Additional styles to apply to the main container
 * @param {boolean} props.showDateInputs Whether to show date input fields
 */
const GanttChart = ({
  items = [],
  title = "График",
  statusColors = {}, // kept for backwards compatibility
  getItemColor, // new function to get color based on item properties
  onDateChange,
  loading = false,
  labelHeader = "Название",
  emptyMessage = "Нет данных для отображения.",
  readOnly = false,
  calculateTimelineDates,
  sx = {},
  showDateInputs = true,
}) => {
  // Refs for tracking drag operations
  const dragInfo = useRef({
    resizingSide: null, // 'left', 'right', 'move', or null
    resizingItem: null,
    startX: 0,
    startWidth: 0,
    startLeft: 0
  });
  
  // Further reduced day width to fit the entire year on screen
const dayWidth = 2.5; // Width of each day cell in pixels

  // Generate array of dates for the timeline based on items
  const generateDefaultTimelineDates = (items) => {
    let minDate = new Date();
    let maxDate = new Date();

    if (items && items.length) {
      // Find earliest start date and latest end date
      items.forEach(item => {
        const startDate = new Date(item.start_date);
        const endDate = new Date(item.end_date);
        
        if (startDate < minDate) minDate = startDate;
        if (endDate > maxDate) maxDate = endDate;
      });
      
      // Add buffer days
      minDate.setDate(minDate.getDate() - 1);
      maxDate.setDate(maxDate.getDate() + 1);
    } else {
      // Default date range if no items
      minDate.setDate(minDate.getDate() - 3);
      maxDate.setDate(maxDate.getDate() + 3);
    }
    
    const numDays = Math.ceil((maxDate - minDate) / (1000 * 60 * 60 * 24));
    return generateDates(minDate, numDays);
  };

  // Generate array of dates from a start date
  const generateDates = (startDate, daysCount) => {
    const dates = [];
    const start = new Date(startDate);
    
    for (let i = 0; i < daysCount; i++) {
      const date = new Date(start);
      date.setDate(date.getDate() + i);
      dates.push(date);
    }
    
    return dates;
  };

  // Calculate position and width for task bar
  const calculateTaskPosition = (startDate, endDate, timelineDates) => {
    // Convert to Date objects
    const start = new Date(startDate);
    const end = new Date(endDate);
    const firstDayOfYear = new Date(timelineDates[0].getFullYear(), 0, 1);
    
    // Calculate day of year for start and end dates
    const getDayOfYear = (date) => {
      return Math.floor((date - new Date(date.getFullYear(), 0, 1)) / (24 * 60 * 60 * 1000));
    };
    
    const startDayOfYear = getDayOfYear(start);
    const endDayOfYear = getDayOfYear(end);
    
    // Calculate position based on days of year
    const left = startDayOfYear * dayWidth;
    const width = Math.max((endDayOfYear - startDayOfYear + 1) * dayWidth, 10); // Minimum width of 10px for visibility
    
    return { left, width };
  };

  // Use provided timeline dates function or fallback to default
  const timelineDates = calculateTimelineDates ? 
    calculateTimelineDates(items) : 
    generateDefaultTimelineDates(items);

  // Mouse event handlers for resizing
  const handleMouseDown = (e, item, side) => {
    if (readOnly) return;
    
    e.preventDefault();
    e.stopPropagation();
    
    console.log('Mouse down on side:', side);
    
    // Use computed style for accurate measurements
    const taskBar = document.getElementById(`task-bar-${item.id}`);
    const computedStyle = window.getComputedStyle(taskBar);
    
    // Get actual values from styles
    const startLeft = parseInt(computedStyle.left, 10);
    const startWidth = parseInt(computedStyle.width, 10);
    
    console.log(`Initial position - left: ${startLeft}, width: ${startWidth}`);
    
    // Update drag info for immediate access
    dragInfo.current = {
      resizingSide: side,
      resizingItem: item,
      startX: e.clientX,
      startWidth: startWidth,
      startLeft: startLeft
    };
    
    // Store references to bound functions for proper cleanup
    const boundMouseMove = (event) => handleMouseMove(event);
    const boundMouseUp = (event) => handleMouseUp(event);
    
    window._currentMouseMove = boundMouseMove;
    window._currentMouseUp = boundMouseUp;
    
    document.addEventListener('mousemove', boundMouseMove);
    document.addEventListener('mouseup', boundMouseUp);
  };

  const handleMouseMove = (e) => {
    const { resizingItem, resizingSide, startX, startWidth, startLeft } = dragInfo.current;
    
    console.log('handleMouseMove called', 'resizingItem:', resizingItem?.id, 'resizingSide:', resizingSide);
    
    if (!resizingItem || !resizingSide) {
      console.log('No resizing info, returning early');
      return;
    }
    
    const diff = e.clientX - startX;
    const taskBarElement = document.getElementById(`task-bar-${resizingItem.id}`);
    
    if (!taskBarElement) {
      console.log('Task bar element not found');
      return;
    }
    
    console.log('Mouse move, resizing side:', resizingSide, 'diff:', diff);
    
    // Minimum width for the task bar - one day
    const minWidth = dayWidth;
    
    if (resizingSide === 'right') {
      // Resize from right - changes end date
      const newWidth = Math.max(minWidth, startWidth + diff);
      console.log('Setting right width to:', newWidth);
      taskBarElement.style.width = `${newWidth}px`;
    } else if (resizingSide === 'left') {
      // Resize from left - changes start date and width
      // Limit how far left edge can move to maintain minimum width
      const maxLeftShift = startWidth - minWidth;
      
      let newLeft = startLeft;
      let newWidth = startWidth;
      
      if (diff > 0) {
        // Moving right - increase left, decrease width
        const amountToReduce = Math.min(diff, maxLeftShift);
        newLeft = startLeft + amountToReduce;
        newWidth = startWidth - amountToReduce;
      } else {
        // Moving left - decrease left, increase width
        // Don't allow left to go below 0
        const maxLeftDecrease = Math.min(Math.abs(diff), startLeft);
        newLeft = startLeft - maxLeftDecrease;
        newWidth = startWidth + maxLeftDecrease;
      }
      
      console.log('Left resize - diff:', diff, 'maxLeftShift:', maxLeftShift, 'newLeft:', newLeft, 'newWidth:', newWidth);
      
      // Apply changes, ensuring minimum width
      if (newWidth >= minWidth) {
        taskBarElement.style.left = `${newLeft}px`;
        taskBarElement.style.width = `${newWidth}px`;
      }
    } else if (resizingSide === 'move') {
      // Move the entire task bar - changes both start and end dates but preserves duration
      const newLeft = Math.max(0, startLeft + diff);
      console.log('Moving bar to position:', newLeft);
      taskBarElement.style.left = `${newLeft}px`;
    }
  };

  const handleMouseUp = () => {
    const { resizingItem, resizingSide } = dragInfo.current;
    
    console.log('Mouse up - resizingItem:', resizingItem?.id, 'resizingSide:', resizingSide);
    
    if (resizingItem && resizingSide) {
      // Calculate new dates based on position and width
      const taskBarElement = document.getElementById(`task-bar-${resizingItem.id}`);
      
      if (taskBarElement) {
        const left = parseInt(taskBarElement.style.left || '0', 10);
        const width = taskBarElement.offsetWidth;
        
        // Convert pixels to day indices
        const startDayIndex = Math.round(left / dayWidth);
        const endDayIndex = Math.round((left + width) / dayWidth) - 1;
        
        // Ensure valid indices
        if (startDayIndex >= 0 && endDayIndex >= startDayIndex && timelineDates[startDayIndex] && timelineDates[endDayIndex]) {
          // Format dates as YYYY-MM-DD
          const formatDate = (date) => date.toISOString().split('T')[0];
          const newStartDate = formatDate(timelineDates[startDayIndex]);
          const newEndDate = formatDate(timelineDates[endDayIndex]);
          
          console.log('New dates calculated:', newStartDate, 'to', newEndDate);
          
          // Only update if dates have changed
          if (newStartDate !== resizingItem.start_date || newEndDate !== resizingItem.end_date) {
            // Call the callback with the updated dates
            if (onDateChange) {
              onDateChange(resizingItem.id, { start_date: newStartDate, end_date: newEndDate });
            }
          }
        }
      }
    }
    
    // Clean up
    dragInfo.current = {
      resizingSide: null,
      resizingItem: null,
      startX: 0,
      startWidth: 0,
      startLeft: 0
    };
    
    // Remove event listeners
    if (window._currentMouseMove) {
      document.removeEventListener('mousemove', window._currentMouseMove);
      window._currentMouseMove = null;
    }
    
    if (window._currentMouseUp) {
      document.removeEventListener('mouseup', window._currentMouseUp);
      window._currentMouseUp = null;
    }
  };

  return (
    <GanttContainer elevation={3} sx={sx}>
      {title && (
        <>
          <Typography variant="h6" gutterBottom>
            {title}
          </Typography>
          <Divider sx={{ mb: 2 }} />
        </>
      )}
      
      {/* Timeline header with months */}
      <TimelineHeader>
        <TaskLabel>{labelHeader}</TaskLabel>
        <Box sx={{ display: 'flex', flex: 1, position: 'relative' }}>
          {/* Month labels */}
          {Array.from({ length: 12 }, (_, i) => i).map(month => {
            const monthDate = new Date(timelineDates[0].getFullYear(), month, 1);
            const daysInMonth = new Date(monthDate.getFullYear(), month + 1, 0).getDate();
            const monthWidth = daysInMonth * dayWidth;
            
            // Calculate position based on month index instead of finding day
            // This ensures all months are displayed even if some dates are missing
            const daysBeforeThisMonth = Array.from({ length: month }, (_, i) => 
              new Date(timelineDates[0].getFullYear(), i + 1, 0).getDate()
            ).reduce((sum, days) => sum + days, 0);
            
            const monthStartPos = daysBeforeThisMonth * dayWidth;
            
            const monthNames = ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'];
            
            return (
              <Box 
                key={month} 
                sx={{ 
                  position: 'absolute',
                  left: `${monthStartPos}px`,
                  width: `${monthWidth}px`,
                  textAlign: 'center',
                  borderLeft: '1px solid #e0e0e0',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '0.75rem',
                  fontWeight: 'bold'
                }}
              >
                {monthNames[month]}
              </Box>
            );
          })}
        </Box>
        {showDateInputs && (
          <>
            <DateColumn>Дата начала</DateColumn>
            <DateColumn>Дата окончания</DateColumn>
          </>
        )}
      </TimelineHeader>
      
      {/* Task rows */}
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      ) : items && items.length > 0 ? items.map((item) => {
        const position = calculateTaskPosition(
          item.start_date, 
          item.end_date, 
          timelineDates
        );
        
        const startDate = item.start_date ? new Date(item.start_date) : null;
        const endDate = item.end_date ? new Date(item.end_date) : null;
        
        const handleDateInputChange = (dateType, newDate) => {
          if (!newDate) return;
          
          const formattedDate = newDate.toISOString().split('T')[0];
          
          if (dateType === 'start') {
            if (endDate && newDate > endDate) {
              // If new start date is after end date, adjust end date
              onDateChange(item.id, { start_date: formattedDate, end_date: formattedDate });
            } else {
              onDateChange(item.id, { start_date: formattedDate, end_date: item.end_date });
            }
          } else if (dateType === 'end') {
            if (startDate && newDate < startDate) {
              // If new end date is before start date, adjust start date
              onDateChange(item.id, { start_date: formattedDate, end_date: formattedDate });
            } else {
              onDateChange(item.id, { start_date: item.start_date, end_date: formattedDate });
            }
          }
        };
        
        return (
          <TaskRow key={item.id}>
            <TaskLabel>
              <Tooltip title={item.curator ? `${item.curator.first_name || ''} ${item.curator.last_name || ''}`.trim() : 'Куратор не назначен'}>
                <Typography sx={{ fontSize: '0.9rem', lineHeight: 1.2 }}>
                  {item.curator 
                    ? `${item.curator.first_name || ''} ${item.curator.last_name || ''}`.trim() 
                    : 'Куратор не назначен'}
                </Typography>
              </Tooltip>
            </TaskLabel>
            
            <TimelineGrid>
              <TaskBar 
                id={`task-bar-${item.id}`}
                color={getItemColor ? getItemColor(item) : (statusColors ? statusColors[item.status] : '#757575')}
                style={{
                  left: `${position.left}px`,
                  width: `${position.width}px`,
                }}
                onMouseDown={!readOnly ? (e) => {
                  // Middle area - move the entire bar
                  handleMouseDown(e, item, 'move');
                } : undefined}
              >
                {!readOnly && (
                  <>
                    {/* Explicit resize handles */}
                    <ResizeHandle 
                      position="left" 
                      onMouseDown={(e) => {
                        e.stopPropagation();
                        console.log('Left handle mousedown');
                        handleMouseDown(e, item, 'left');
                      }}
                    />
                    <ResizeHandle 
                      position="right" 
                      onMouseDown={(e) => {
                        e.stopPropagation();
                        console.log('Right handle mousedown');
                        handleMouseDown(e, item, 'right');
                      }}
                    />
                  </>
                )}
                <Box sx={{ maxHeight: '100%', overflow: 'hidden' }}>
                  {item.name}
                </Box>
              </TaskBar>
            </TimelineGrid>
            
            {showDateInputs && (
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
                <DateColumn>
                  <DatePicker
                    value={startDate}
                    onChange={(newDate) => handleDateInputChange('start', newDate)}
                    slotProps={{
                      textField: {
                        size: "small",
                        fullWidth: true,
                        variant: "standard",
                        InputProps: { readOnly: readOnly }
                      }
                    }}
                    readOnly={readOnly}
                  />
                </DateColumn>
                <DateColumn>
                  <DatePicker
                    value={endDate}
                    onChange={(newDate) => handleDateInputChange('end', newDate)}
                    slotProps={{
                      textField: {
                        size: "small",
                        fullWidth: true,
                        variant: "standard",
                        InputProps: { readOnly: readOnly }
                      }
                    }}
                    readOnly={readOnly}
                  />
                </DateColumn>
              </LocalizationProvider>
            )}
          </TaskRow>
        );
      }) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <Typography variant="body1" color="text.secondary">
            {emptyMessage}
          </Typography>
        </Box>
      )}
    </GanttContainer>
  );
};

export default GanttChart;
