// src/pages/resourcePlanPage.js

import React from "react";
import { Container, Typography } from "@mui/material";

const ResourcePlanPage = () => {
  return (
    <Container maxWidth="xl" sx={{ px: "5mm", mt: 3 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Ресурсный план
      </Typography>
      <Typography variant="body1">
        Страница ресурсного планирования находится в разработке.
      </Typography>
    </Container>
  );
};

export default ResourcePlanPage;
